
  /* @font-face { font-family: "Somerton Dense"; src: url('./somerton_dense/Somerton Dense df.otf'); }  */
  @font-face { font-family: "Poppins"; src: url('./Fonts/Poppins/Poppins-Regular.ttf'); } 
  /* @font-face { font-family: "Montserrat"; src: url('./Fonts/Montserrat/Montserrat-SemiBold.ttf'); }  */

    html{
        scroll-behavior: smooth;
    }
    body{
        /* background-Color: #080027; */
    }

    .homeMain {
      background: linear-gradient( rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75) ), url(./images/homeBg.png);
      background-size: cover;
      margin-top: -10px;
      min-height: calc(100vh - 81px);
      display: flex;
      align-items: center;

    }
    .homeMain .heading {
      font-weight: 700;
      margin-bottom: 30px;
    }
    .homeMain .claimBtnDiv {
      display: flex;
      background-color: #F7F7F7;
      border-radius: 10px;
      margin-bottom: 20px;
    }
    .homeMain .claimBtnDiv .createBtn{
      background: linear-gradient(180deg, #2891d9, #08a7df);
      color: white;
      border-radius: 10px;
      transition: all 0.3s;
    }
    .homeMain .claimBtnDiv .createBtn:hover{
      opacity: 0.8;
      transition: all 0.3s;
    }
    .homeMain .claimBtnDiv .claimBtn{
      border-radius: 10px;
      transition: all 0.3s;
    }
    .homeMain .claimBtnDiv .claimBtn:hover{
      background-color: #e6e5e5;
      transition: all 0.3s;
    }
    .homeMain .amountDivMain {
      margin-bottom: 20px;
    }
    .homeMain .amountInputDiv {
      display: flex;
      align-items: center;
      height: 45px;
      background-color: #F7F7F7;
      border-radius: 10px;
      padding: 2px 10px;
    }
   
    .homeMain .amountInputDiv input{
      background: transparent;
      margin-left: 5px;
      border: none;
    }
    .homeMain .amountInputDiv .max{
      background: linear-gradient(180deg, #2891d9, #08a7df);
      color: white;
      border-radius: 8px;
      transition: all 0.3s;
      border: none;
      font-size: 16px;
      padding: 1px 14px;
      font-weight: 500;
    }
    .homeMain .amountInputDiv .max:hover{
      opacity: 0.8;
      transition: all 0.3s;
    }
    .homeMain .amountInputDiv .imgDiv{
      padding-left: 20px;
      padding-right: 10px;
      border-right: 1px solid;
    }
    .homeMain .amountInputDiv .imgDiv img{
      width: 38px;
    }
    .homeMain .amountDiv {
      display: flex;justify-content: space-between;
      margin-bottom: 7px;
    }
    .homeMain .amountDiv p{
      margin-bottom: 0;
      font-size: 13px;
    }
    .homeMain .fromToMain {
      display: flex;
      align-items: flex-end;
      margin-bottom: 20px;
    }
    .homeMain .fromToMain p{
      margin-bottom: 0;
    }
    .homeMain .fromToMain .fromDivMain{
      width: 100%;
    }
    .homeMain .fromToMain .fromDiv{
      background: #F7F7F7;
      padding: 10px 10px 10px 25px;
      display: flex;
      align-items: center;
      border-radius: 10px;
      height: 48.8px;
    }
    .homeMain .fromToMain .fromDiv img{
      margin-right: 10px;
      height: 28px;
    }
    .homeMain .fromToMain .fromDiv p{
      margin: 0;
      font-weight: 500;
    }
    .homeMain .fromToMain .from{
     font-size: 13px; 
    }
    .homeMain .fromToMain .to{
     font-size: 13px;
    }
    .homeMain .fromToMain .convertIconDiv{
      border-radius: 50%;
      /* width: 100px; */
      padding: 13px;
      /* height: 50px; */
      justify-content: center;
      display: table-column;
      display: flex;
      align-items: center;
      background: #f7f7f7;
      margin: 0 10px;
      cursor: pointer;
    }
    .homeMain .fromToMain .convertIconDiv img{
      width: 22px;
      height: 22px;
      transition: all 0.3s;
    }
    .homeMain .feeMain {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
    }
    .homeMain .feeMain p{
      margin-bottom: 0;
      font-size: 15px;
      color: #1A1818;
    }
    .homeMain .CreateCrossBtn {
      background: linear-gradient(180deg, #2891d9, #08a7df);
      color: white;
      border-radius: 10px;
      transition: all 0.3s;
    }
    .homeMain .CreateCrossBtn:active{
      opacity: 1;
      transition: all 0.3s;
      color: black;
    }
    .homeMain .CreateCrossBtn:hover {
      color: white;
      opacity: 0.8;
      transition: all 0.3s;
    }
    .homeMain button:focus {
      box-shadow: none;
    }
    .homeMain input:focus{
      box-shadow: none;
    }
    .connect-wallet-BtnDiv button{
      background: linear-gradient(180deg, #2891d9, #08a7df);
      border: none;
      border-radius: 10px !important;
      width: 100% !important;
      padding: 8px !important;
      max-width: 100% !important;

    }

    .mintSec{
      max-width: 500px;
      margin: 20px auto;
      align-items: end;
      padding:20px;
      width: 100%;
      border-radius: 16px;
      background: white;
      box-shadow: 0px 2px 20px -1px rgba(81,118,229,0.3);
      -webkit-box-shadow: 0px 2px 20px -1px rgba(81,118,229,0.3);
      -moz-box-shadow: 0px 2px 20px -1px rgba(81,118,229,0.3);
    }
    .mintSec form{
      /* grid-row-start: none; */
      box-shadow: 0px 0px 21px 3px rgb(27 81 150 / 21%);
      padding: 10px;
      border-radius: 20px;
      padding: 30px;
      background: white;
    }
    .mintSec .uploadImgDiv{
      /* background-color: #b6b6b6; */
      border-radius: 20px;
      /* color: white; */
      height: 140px;
      text-align: center;
      display: flex;
    }
    .mintSec .uploadImgDiv label{
      width: 100%;
      height: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      background-color: #b6b6b6;
      border-radius: 12px;

    }
    .mintSec .uploadImgDiv .showImg{
      width: 100%;
      border: 2px solid gray;
      border-radius: 12px;
      margin-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 246px;
    }
    .mintSec .uploadImgDiv .showImg img{
     
      max-height: 100%;
      padding: 10px;
      border-radius: 10px;
    }
    .mintSec .uploadImgDiv label input{
      display: none;
    }
    .mintSec .uploadImgDiv img{
      max-height: 100%;
    }
    .mintSec .attributeDiv{
      display: flex;
      align-items: center;
    }
    .mintSec .removeDiv{

      display: inline-block;
      margin-left: 10px;
      cursor: pointer;
    }
    .mintSec .removeDiv img{
      width: 30px;
    }
    .creatBtn{
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      top: 50%;
    }

    /* profile page */
    .ProfileSec{
      margin: 40px 0;
    }
    .ProfileSec .ownerDiv{
      margin-bottom: 50px;
    }
    .ProfileSec .ownerDiv .imgDiv{    
      height: 100px;
      width: 100px;
    }
    .ProfileSec .ownerDiv .imgDiv img{
      max-width: 100%;
      max-height: 100%;
      border-radius: 50%;
    }
    .ProfileSec .ownerCollection .imgDiv{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px;
      border-bottom: 2px solid rgb(172, 172, 172);
    }
    .ProfileSec .ownerCollection .imgDiv img{
      max-width: 100%;
      max-height: 100%;
    }
    .ProfileSec .ownerCollection .nftCard{
      padding: 10px;
      border: 2px solid #908787;
      border-radius: 10px;
    }
.listtingLoader{
  display: flex;
  top: 0;
  justify-content: center;
    flex-direction: column;
    align-items: center;
    position: fixed;
    height: 100vh;
    left: 0;
    width: 100vw;
    right: 0;
    backdrop-filter: blur(2px);
    background: rgba(0,0,0,0.3);
}
.listtingLoader .listtingLoaderInner{
      text-align: center;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    width: 350px;
}

.navbar .walletBtn{
  position: relative;
  background: linear-gradient(180deg, #2891d9, #08a7df);
  border: none;
  font-size: 14px;
  padding: 9px 35px;
  border-radius: 8px;
  font-weight: 600;
}

.tooltip1 {
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  top: 39px;
  left: 50%;
  transform: translate(-50%,0);
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  display: none;
}
.navbar .walletBtn:hover {
  opacity: 0.8;
}
.navbar .walletBtn:hover .tooltip1{
  display: block !important;
}
.navbar .balanceBtn .bsc{
  width: 23px;
  margin-right: 5px;
}
.navbar .balanceBtn .eth{
  width: 15px;
  margin-right: 5px;
}
.navbar .balanceBtn{
  background: #F7F7F7;
  padding: 7px 20px;
  font-size: 14px;
  border-radius: 8px;
  font-weight: 600;
  position: relative;
  right: -7px;
}
.navbar .navbar-brand .imgDIv {
  width: 100px;
}
.walletModalBtn .imgDIv{
  width: 110px;
  height: 100px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}
.walletModalBtn {
  width: 100%;
}
.walletModalBtn .imgDIv img{
  max-width: 100%;
  height: auto;
}
.adminLoginForm {
  max-width: 450px;
  margin: 20px auto;
  align-items: end;
  padding: 20px;
  width: 100%;
  border-radius: 16px;
  background: white;
  box-shadow: 0px 2px 20px -1px rgba(81, 118, 229, 0.3);
  -webkit-box-shadow: 0px 2px 20px -1px rgba(81, 118, 229, 0.3);
}
.adminLoginForm input {
  background-color: #F7F7F7;
  border: none;
}
 .sBtn {
  background: linear-gradient(180deg, #2891d9, #08a7df);
  color: white !important;
  border-radius: 10px;
  transition: all 0.3s;
}
.adminLoginForm label {
  font-weight: 500;
}

