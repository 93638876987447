.seekoLoader {
  position: fixed;
  top: 0;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,.2);
  z-index: 1111;
}
.seekoLoader .loader {
      width: 100%;
      max-width: 500px;
      padding: 40px 0;
      text-align: center;
      -webkit-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
      top: 50%;
      left: 50%;
      background: #fff;
      border-radius: 20px;
      position: fixed;
  }
  .seekoLoader .loader h2 {
      font-weight: 700;
      margin-top: 40px;
      margin-bottom: 10px;

  }
  .seekoLoader .loader h4 {
      margin-bottom: 10px;
  }
  .text-bold {
      font-weight: bold;
  }
  .seekoLoader .loader p {
      margin-bottom: 0px;
      font-size: 22px;
  }
  .seekoLoader img {
      width: 90px;
      animation: beat .4s infinite alternate;
  }
  
  .homeMain .claimCardMain{
      max-height: 365px;
      overflow-y: scroll;
  }

  .homeMain .claimCardMain::-webkit-scrollbar-track
  {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
      background-color: #F5F5F5;
  }

  .homeMain .claimCardMain::-webkit-scrollbar
  {
      width: 8px;
      background-color: #F5F5F5;
  }

  .homeMain .claimCardMain::-webkit-scrollbar-thumb
  {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background: linear-gradient(rgb(40, 145, 217), rgb(8, 167, 223)) ;
  }

  .navbar.bgGray {
    background-color: #F7F7F7;
  }

  .homeMain .claimMain {
      padding: 15px;
      background: #f7f7f7;
      border-radius: 10px;
      margin-top: 10px;
      padding-top: 20px;
      position: relative;
    }
  .homeMain .claimMain .date {
      position: absolute;
      left: 8px;
      top: 5px;
      font-size: 12px;
      color: gray;
      font-weight: bold;
  }
  .homeMain .claimMain .currency img{
      padding: 0 3px ;
      max-height: 25px;
  }
  .homeMain .claimMain .currency{
     text-align: center;
     color: gray;
     font-size: 14px;
  }
  .homeMain .claimMain .contentMain{
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .homeMain .claimMain .contentMain .col1{
      font-weight: 600;
      text-transform: uppercase;
      font-size: 17px;
  }
  .homeMain .claimMain .contentMain .col1 img{
      width: 30px;
  }
  .homeMain .claimMain .contentMain .col2 p {
      font-size: 14px;
      margin-bottom: 0;
  }
  .homeMain .claimMain .contentMain .col2 h5{
      font-weight: 700;
      margin-bottom: 0;
  }
  .homeMain .claimtokenbtn {
      background: linear-gradient(rgb(40, 145, 217), rgb(8, 167, 223));
      color: rgb(255, 255, 255);
      border-radius: 10px;
      margin-top: 15px;
  }
  .homeMain .disabled {
      cursor: not-allowed;
  }
  .homeMain .disabled:hover {
      color: white;
  }

  /* Heart beat animation */
  @keyframes beat{
      to { transform: scale(1.3); 
      }
  }

  .modal .modal-body {
      text-align: center;
      padding: 60px 20px;
  }
  .modal .modal-body h2{
      font-weight: 700;
      margin-bottom: 30px;
  }
  .modal .modal-body h2{
      font-weight: 700;
      margin-bottom: 30px;
  }
  .modal .modal-body p {
      font-size: 23px;
      color: black;
  }
  .modal .modal-body p {
      font-size: 23px;
      color: black;
  }
  .modal .modal-body button {
      padding: 12px;
      width: 100%;
      max-width: 150px;
      border-radius: 10px;
      font-weight: 500;
      font-size: 18px;
  }
  .modal .modal-body .btnDiv  {
      margin-top: 35px;
  }
  .modal .modal-content  {
      border-radius: 20px;
      width: 100%;
  }
  .modal .modal-body .confirm {
      background: linear-gradient(180deg, #2891d9, #08a7df);
      color: white;
  }
  .modal .modal-body .cancel {
      box-shadow: 0px 2px 12px -1px rgba(0,0,0,0.20);
      -webkit-box-shadow: 0px 2px 12px -1px rgba(0,0,0,0.20);
      -moz-box-shadow: 0px 2px 12px -1px rgba(0,0,0,0.20);
      margin-right: 20px;
  }
  .modal .modal-body .hashLink {
      font-size: 20px;
      font-weight: 500;
  }
  .modal-dialog {
      min-height: 100%;
      display: flex;
      align-items: center;
  }
.adminMain {
      position: relative;
      background-color: #F7F7F7;
}
.adminMain .claimTxMain {
    padding: 20px;
    /* border: 1px solid lightgray; */
    background-color: #fff;
    border-radius: 10px;
}
.adminMain .adminMainInner {
    max-width: 1500px;
    margin: auto;
    padding: 16px;
}
.adminMain .adminMainInner .statsCardMain .statsCard {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    height: 100%;
}
.adminMain .adminMainInner .statsCardMain .statsCard .title {
    color: #8f9fb4;
    font-size: 12px;
    font-weight: 500;
}
.adminMain .adminMainInner .statsCardMain .statsCard .value {
    font-size: 26px;
    font-weight: 700;
    color: #5a5c69 !important;
}
.adminMain .adminMainInner .statsCardMain .statsCard .ico i {
    font-size: 30px;
    color: #5a5c69;
}
.adminMain .adminMainInner .statsCardMain .statsCard .content {
    min-width: 105px;
    padding-left: 20px;
}
.adminMain .adminMainInner .statsCardMain .setMaxCard {
    background: linear-gradient(180deg, #2891d9, #08a7df);
    color: #fff;
    font-size: 30px;
    text-align: center;
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    border-radius: 10px;
    padding: 20px;
    height: 100%;
    line-height: 50px;
    cursor: pointer;
}
.maxsupplyForm {
    text-align: left;
}
.maxsupplyForm label {
    font-weight: 500;
}
.maxsupplyForm input {
    background-color: #F7F7F7;
    border: none;
}

  @media (min-width: 576px){
      .modal-dialog {
          max-width: 600px !important;
          margin: 1.75rem auto;
      }
  }
